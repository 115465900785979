import { addIcons } from 'ionicons'

import close from 'ionicons/dist/svg/close.svg'
import closeOutline from 'ionicons/dist/svg/close-outline.svg'
import menu from 'ionicons/dist/svg/menu.svg'
import chevronForward from 'ionicons/dist/svg/chevron-forward.svg'
import chevronForwardOutline from 'ionicons/dist/svg/chevron-forward-outline.svg'
import chatbubbleEllipses from 'ionicons/dist/svg/chatbubble-ellipses.svg'
import checkmarkCircleOutline from 'ionicons/dist/svg/checkmark-circle-outline.svg'

export function registerIcons () {
  addIcons({
    'close': close,
    'close-outline': closeOutline,
    'menu': menu,
    'chevron-forward': chevronForward,
    'chevron-forward-outline': chevronForwardOutline,
    'chatbubble-ellipses': chatbubbleEllipses,
    'checkmark-circle-outline': checkmarkCircleOutline,
  })
}
